import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material";
import axios from "axios";
import { useEffect, useState } from "react";

import { toast } from "react-toastify";

const Faq = () => {
    const [data, setData] = useState();

    const getCreer = () => {
        axios
            .get(`https://m1blog.aaragroups.com/blog/faq-api/`, {
                headers: {
                    Authorization: `Token ${localStorage.getItem("token")}`,
                    /*  Authorization: "Token 4dc44049741a108d3b2032fcf3aa1ef799c26f23", */
                    "Access-Control-Allow-Origin": "*",
                },
            })
            .then((res) => {
                setData(res.data?.data);
            })
            .catch((err) => {
                toast.error("Error deleting blog", err?.response?.data?.message);
            });
    };
    useEffect(() => {
        getCreer();
        window.scrollTo({
            top: 0,
            behavior: "instant",
        });
    }, []);
    return (
        <div className="relative  pt-10 bg-[#fcfcfc] bg-opacity-20 backdrop-blur border lg:rounded-xl shadow-lg shadow-slate-500 lg:w-[70%] lg:mx-auto">
            <div className="flex items-start justify-center ">
                <div className="flex flex-col gap-4 p-4 pb-4">
                    <div className="flex flex-col gap-1">
                        <p className="font-serif text-3xl font-bold text-center lg:text-4xl">
                            Frequently Asked Questions
                        </p>

                    </div>

                    {data?.map((faq, index) => (
                        <Accordion key={index}>
                            <AccordionSummary
                                expandIcon={<ExpandMore />}
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                                className="text-xl !font-bold"
                            >
                                <Typography >{faq.question}</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>{faq.answer}</Typography>
                            </AccordionDetails>
                        </Accordion>
                    ))}

                </div>
            </div></div>
    );
};

export default Faq;
